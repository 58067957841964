
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1, h2, h3, h4, h5, h6, p, a, span, small {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.headbox {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.contentMenu {
  background-color: #e3e4e6;
  height: 40px;
  text-align: center;
  -webkit-transition: height 1s;
  /* Safari */
  transition: height 1s;
}
.contentMenu:hover {
  height: 100px;
}

body {
  --color-background: #fafafa;
  --color-foreground: #1f2023;
}
body.dark {
  --color-background: #1f2023!important;
  --color-foreground: #efefef!important;
}


.rounded-top-left-1 {
  border-top-left-radius: 1rem;
}
.rounded-top-right-1 {
  border-top-right-radius: 1rem;
}
.rounded-bottom-left-1 {
  border-bottom-left-radius: 1rem;
}
.rounded-bottom-right-1 {
  border-bottom-right-radius: 1rem;
}

.rounded-top-right-4 {
  border-top-right-radius: 4rem;
}
.rounded-bottom-right-4 {
  border-bottom-right-radius: 4rem;
}

.rounded-bottom-left-18 {
  border-bottom-left-radius: 18rem;
}

#btn-start_into {
  width: 150px;
  font-size: 1.4em;
  margin-right: 50px;
  margin-bottom: -30px;
}

#title-position {
  margin-left: 15vw;
}

#qr_footer {
  height: 30vh;
}

.hover-div:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.product-card-image img{
  height: 350px;
  width: 100%;
  object-fit: contain;
}

.portfolio-card-image img {
  height: 450px;
  width: 100%;
  object-fit: cover;
  /*border-top-right-radius: 20px;*/
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 20px;
}

.portfolio-card-info {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 35px;
  background: #D9D9D9;
  padding: 20px;
  margin-top: 20px;
  padding-top: 50px;
  height: 350px;
  margin-bottom: 15px;
}

.service-item img{
  width: 100%;
  height: 40vh;
  object-fit: contain;
}

.blog-post-cover img, .cover img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

a.footer-link:hover {
  color: white!important;
}

.title-welcome {
  margin-top: 30vh;
}

.radio-img > input {
  display: none;
}
.radio-img > .image {
  cursor: pointer;
  border: 2px solid black;
}
.radio-img > input:checked + .image {
  border: 2px solid orange;
}

.flag-language img:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 50%;
}

#welcome {
  height: 220vh
}

@media only screen and (max-width: 600px) {
  #welcome {
    height: 100vh
  }
}
a.mobile-link {
  color: black!important;
}

#main_title_text{
  font-weight: bold;
  margin-top: 20vh;
  font-size: 240px;
}

@media only screen and (max-width: 600px) {
  #main_title_text{
    font-weight: bold;
    margin-top: 20vh;
    font-size: 50px;
  }

  #main_text, a{
    font-size: 20px!important;
  }
}

#main_text, a {
  font-size: 40px;
}

#main_link > small {
  font-weight: bold;
}

.email {
  font-size: 20px;
}

a.nav-link:hover{
  color: black!important;
  text-decoration: underline!important;
  text-underline-position: under
}

.img-stack-technologies {
  /*-webkit-filter: saturate(0%) grayscale(100%) brightness(69%) contrast(1000%)*/
  -webkit-filter: grayscale(100%)
}

.img-stack-technologies:hover {
  -webkit-filter: grayscale(0%)
}

.field {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*width: 300px;*/
  /*position: relative;*/
}

.mouse {
  width: 50px;
  height: 90px;
  border: 3px solid #cdcdcd;
  border-radius: 60px;
  position: relative;
}

.mouse::before {
   content: '';
   width: 12px;
   height: 12px;
   position: absolute;
   top: 10px;
   left: 50%;
   transform: translateX(-50%);
   background-color: #cdcdcd;
   border-radius: 50%;
   opacity: 1;
   animation: wheel 2s infinite;
   -webkit-animation: wheel 2s infinite;
 }

@keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@keyframes scroll {
  0% {
    height: 40px;
  }
  30% {
    height: 70px;
  }
  60% {
    height: 40px;
  }
}

@-webkit-keyframes scroll {
  0% {
    height: 40px;
  }
  30% {
    height: 70px;
  }
  60% {
    height: 40px;
  }
}

.qr-tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
  margin-right: 20px;
}

.qr-tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.qr-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.qr-tooltip.top .tooltiptext {
  bottom: 100%;
  top: auto;
}

.qr-tooltip.bottom .tooltiptext {
  top: 100%;
  bottom: auto;
}
