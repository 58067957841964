:root {
    --navBg: #1E1E1E;
}

.navbar {
    /*background-color: var(--navBg);*/
    /*background-color: white;*/
    /*backdrop-filter: blur(50px);*/
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars {
    /*margin-left: 2rem;*/
    /*font-size: 2rem;*/
    background: none;
}

.nav-menu {
    background-color: var(--navBg);
    width: 270px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active {
    overflow-y: scroll;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    /*padding: 8px 0 8px 16px;*/
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    /*display: flex;*/
    align-items: center;
    padding: 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    color: white;
    background-color: #404040;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
}

.navbar-toggle {
    background-color: var(--navBg);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

span {
    margin-left: 16px;
}

.nav-menu-items label:hover {
    background-color: white;
    color: #404040 !important;
}
